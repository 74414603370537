import { useEffect } from 'react';
import { useRouter } from 'next/router';
import captureUtmParameters from '../../utils/utmTracking';

const UTMHandler = () => {
  const router = useRouter();

  useEffect(() => {
    // Store UTM parameters on initial load
    captureUtmParameters();

    // Listen for route changes - only check for UTMs on route changes with query parameters
    const handleRouteChange = (url) => {
      if (url.includes('?')) {
        captureUtmParameters();
      }
    };

    // Subscribe to route changes
    router.events.on('routeChangeComplete', handleRouteChange);

    // Cleanup subscription
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);

  // This is a utility component that doesn't render anything
  return null;
};

export default UTMHandler; 