/**
 * UTM Tracking Utility
 * 
 * Captures UTM parameters from URL on first page load and stores them in cookies and localStorage.
 * This allows the parameters to persist across pages for form submissions.
 */

// List of UTM and tracking parameters to capture
const TRACKING_PARAMS = [
  'utm_source',
  'utm_medium', 
  'utm_campaign',
  'utm_term',
  'utm_content',
  'gclid',
  'fbclid',
  '_ga'
];

// Cookie duration in days
const COOKIE_DURATION = 30;

/**
 * Set a cookie with the given name and value
 */
const setCookie = (name, value, days = COOKIE_DURATION) => {
  const date = new Date();
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/;SameSite=Lax`;
};

/**
 * Get cookie value by name
 */
const getCookie = (name) => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  
  return null;
};

/**
 * Capture UTM parameters from URL
 */
export const captureUtmParameters = () => {
  // Only run in browser environment
  if (typeof window === 'undefined') return;
  
  // Check if we already captured UTM params in this session
  const captured = getCookie('utm_captured');
  
  if (captured) {
    // Already captured in this session, no need to check again
    return;
  }
  
  try {
    const urlParams = new URLSearchParams(window.location.search);
    let utmData = {};
    let hasUtmParams = false;
    
    // Check for tracking parameters in URL
    TRACKING_PARAMS.forEach(param => {
      if (urlParams.has(param)) {
        utmData[param] = urlParams.get(param);
        hasUtmParams = true;
      }
    });
    
    if (hasUtmParams) {
      // Store in localStorage for persistence
      localStorage.setItem('utm_data', JSON.stringify(utmData));
      
      // Also store in cookies as backup
      Object.keys(utmData).forEach(key => {
        setCookie(key, utmData[key]);
      });
      
      // Set a flag indicating we've captured UTM data
      setCookie('utm_captured', 'true');
      
      console.log('UTM parameters captured and stored:', utmData);
    } else {
      // No UTM parameters found, set flag to avoid checking again
      setCookie('utm_captured', 'true');
    }
  } catch (error) {
    console.error('Error capturing UTM parameters:', error);
  }
};

/**
 * Get stored UTM parameters for form submission
 */
export const getStoredUtmParameters = () => {
  // Only run in browser environment
  if (typeof window === 'undefined') return {};
  
  try {
    // Try to get from localStorage first
    const storedUtmData = localStorage.getItem('utm_data');
    if (storedUtmData) {
      return JSON.parse(storedUtmData);
    }
    
    // Fallback to cookies if localStorage is empty
    let utmData = {};
    let hasParams = false;
    
    TRACKING_PARAMS.forEach(param => {
      const value = getCookie(param);
      if (value) {
        utmData[param] = value;
        hasParams = true;
      }
    });
    
    return hasParams ? utmData : {};
  } catch (error) {
    console.error('Error retrieving UTM parameters:', error);
    return {};
  }
};

export default captureUtmParameters; 